<template>
  <div>
    <h1>Impostazioni</h1>
    <v-row class="mt-5"> 
      <v-col cols="3">
        <v-card>
          <v-card-title>
            <span class="text-h5">Messaggi di servizio</span>
          </v-card-title>
          <v-card-text>
            Header e footer delle risposte all'ente
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="onMessaggiDiServizio">Modifica</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogModifica" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Modifica valori</span>
        </v-card-title>
        <v-card-text>
          <v-json-editor v-model="jsonWeb" :options="optionsWeb" :plus="true" history @error="onErrorWeb" style="height: 100%;" ref="jsonEditor"/>
          <!-- <small></small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onEditorChiudi">Chiudi</v-btn>
          <v-btn color="blue darken-1" text @click="onEditorSalva">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import VJsonEditor from 'v-jsoneditor'

export default {
  components: {
    VJsonEditor
  },    
  data: () => ({
    dialogModifica: false,
    jsonWeb: '',
    jsonMode: 'code',
    optionsWeb: {
      mode: 'code',
      modes: ['view', 'form', 'code'],
      search: true,
      enableSort: false,
      enableTransform: true
    },
    tipoModifica: null
  }),
  methods: {
    async onMessaggiDiServizio() {
      // leggi messaggi di servizio da DB
      const messaggi = await selfapi.messaggiServizio()
      this.tipoModifica = 'messaggiDiServizio' // per il salvataggio
      // solo per debug
      this.jsonWeb = JSON.parse('{ "valore" : "messaggiDiServizio" }')
      this.dialogModifica = true
    },
    onErrorWeb() {
      console.log('errore da gestire')
    },
    onEditorChiudi() {
      this.jsonWeb = ''
      this.dialogModifica = false
    },
    onEditorSalva() {
      const payload = this.jsonWeb
      // salvare
      this.jsonWeb = ''
      this.dialogModifica = false
    }
  }
}
</script>

<style lang="scss">
   .nested-badge .v-badge--icon .v-badge__badge {
    padding: 0!important;
  }

  .jsoneditor-outer,  .ace_editor.ace-jsoneditor {
    min-height: 65vh;
  }
  @media all and (max-height: 800px) {
    .jsoneditor-outer,  .ace_editor.ace-jsoneditor {
      min-height: 450px;
    }
  }
</style>
